<template>
  <BasicLayout :initVars="initVars">
    <template v-slot:layout-mainbox-custom>
      <v-main v-if="isRoot">
        <span>Member Index</span>
      </v-main>
    </template>
  </BasicLayout>
</template>

<script>
import BasicLayout from '@/layouts/basic';

export default {
  components: { BasicLayout },
  data () {
    return {
      isRoot: true,
      initVars: {
      }
    };
  },
  // beforeCreate () {
  //   console.log('[member]][beforeCreate] ... ');
  // },
  created () {
    // console.log('[member][created] ... ');
    this.isRoot = !(this.$route.matched.length > 1);
    // console.log(this.isRoot);
  }
  // beforeMount () {
  //   console.log('[member][beforeMount] ... ');
  // },
  // mounted () {
  //   console.log('[member][mounted] ... ');
  // },
  // beforeUpdate () {
  //   console.log('[member][beforeUpdate] ... ');
  // },
  // updated () {
  //   console.log('[member][updated] ... ');
  // }
};
</script>
<style scoped>
</style>
